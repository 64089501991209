// DO NOT EDIT THIS FILE !!!! GENERATED DURING BUILD TYPE : Ansible
export const DOL_VERSION='[production]6.0-10334(e2582f7bc9)-C04/12/2024-10:01:04-B04/12/2024-10:06:18' ;
export const DOL_VERSION_JSON = {
  dolVersion: "[production]6.0-10334(e2582f7bc9)-C04/12/2024-10:01:04-B04/12/2024-10:06:18",
  branch: "production",
  latestTag: "6.0",
  revCount: "10334",
  shortHash: "e2582f7bc9",
  longHash: "e2582f7bc975c2caf895cf619fbe7546db0001c1",
  dateCommit: "04/12/2024-10:01:04",
  dateBuild: "04/12/2024-10:06:18",
  buildType: "Ansible",
  } ;
